import { Component } from '@angular/core';
import { NotificationService } from '../../services/notification.service';
import { FAN_AREA_MENU_TITLES } from '../../enums';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-fan-area-menu',
  templateUrl: './fan-area-menu.component.html',
  styleUrls: ['./fan-area-menu.component.scss'],
  imports: [CommonModule, RouterModule],
  standalone: true
})
export class FanAreaMenuComponent {
  items = [
    {
      title: 'Notification',
      icon: 'icon-Notification',
      path: 'notifications',
    }
  ];

  FUN_AREA_MENU_TITLES = FAN_AREA_MENU_TITLES;

  constructor(
    public notificationService: NotificationService
  ) { }
}
