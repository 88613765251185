import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MatDialogModule } from '@angular/material/dialog';
import { OktaAuthModule } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { ToastrModule } from 'ngx-toastr';

import oktaConfig from './app.config';
import { AppRoutingModule } from './app-routing.module';
import { RewardsPageComponent } from './pages/rewards-page/rewards-page.component';
import { NftMarketPageComponent } from './pages/nft-market-page/nft-market-page.component';
import { FanAreaPageComponent } from './pages/fan-area-page/fan-area-page.component';
import { AuctionPageComponent } from './pages/auction-page/auction-page.component';
import { CharityPageComponent } from './pages/charity-page/charity-page.component';



import { AuthInterceptor } from './interceptors/auth.interceptor';
import { LoaderInterceptor } from './interceptors/loader.interceptor';

import { AppComponent } from './app.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { SidebarComponent } from './shared/components/sidebar/sidebar.component';
import { FloatingMenuComponent } from './shared/components/floating-menu/floating-menu.component';
import { LoaderComponent } from './shared/components/loader/loader.component';
import { RouterModule } from '@angular/router';

const oktaAuth = new OktaAuth(oktaConfig.oidc);

@NgModule({
  declarations: [
    AppComponent,
    RewardsPageComponent,
    NftMarketPageComponent,
    FanAreaPageComponent,
    AuctionPageComponent,
    CharityPageComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    CommonModule,
    OktaAuthModule.forRoot({ oktaAuth }),
    MatDialogModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
      closeButton: true,
      timeOut: 5000,
      preventDuplicates: true
    }),
    NgOptimizedImage,
    HeaderComponent,
    SidebarComponent,
    FloatingMenuComponent,
    LoaderComponent,
    RouterModule,
    AppRoutingModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true }
  ]
})
export class AppModule {}
