import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { interval, startWith, Subscription, switchMap } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { AdditionalService } from './shared/services/additional.service';
import { AuthService } from './shared/services/auth.service';
import { NotificationService } from './shared/services/notification.service';
import { NotificationToastComponent } from './shared/components/notification/notification-toast/notification-toast.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    public additionalService: AdditionalService,
    private authService: AuthService, // do not remove, should be injected
    private readonly toastrService: ToastrService,
    private readonly notificationService: NotificationService
  ) { }

  @AutoUnsubscribe()
  timeIntervalSub: Subscription = {} as Subscription;
  @AutoUnsubscribe()
  isHomePageSub: Subscription = {} as Subscription;

  shownNotificationIds: number[] = []

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      const utmSource = params['utmSource'];
      if (utmSource) {
        this.authService.utmSourceId = utmSource;
      }
    });

    this.isHomePageSub = this.additionalService.isHomePageTemplate$.subscribe((isHomePageTemplate) => {
      if (!isHomePageTemplate && isHomePageTemplate !== null) {
        this.timeIntervalSub = interval(60 * 1000).pipe(startWith(0), switchMap(() => this.notificationService.getNotifications())).subscribe((data) => {
          data?.forEach((item) => {
            const isNotShown = !this.shownNotificationIds.includes(item.id);

            if (item.new && isNotShown && this.shownNotificationIds.length < 5) {
              this.toastrService.info(undefined, undefined, {
                toastClass: 'toast-notification ngx-toastr',
                toastComponent: NotificationToastComponent,
                payload: item,
              });

              this.shownNotificationIds.push(item.id);
            }
          });
        });
      }
    });
  }
}
